.resetTime {
  position: relative;
  display: inline-block;
  color: #50e3c2;
  text-decoration: none;
  text-transform: uppercase;
  transition: 0.5s;
  letter-spacing: 4px;
  overflow: hidden;
  padding: 4px;
  margin-top: -3px;
  span {
    position: absolute;
    display: block;
  }
  span:nth-child(1) {
    top: 0;
    left: 0;
    width: 100%;
    height: 4px;
    background: linear-gradient(90deg, transparent, #50e3c2);
    animation: animate1 3s linear infinite;
  }
  @keyframes animate1 {
    0% {
      left: -100%;
    }
    50%,
    100% {
      left: 100%;
    }
  }
  span:nth-child(2) {
    top: -100%;
    right: 0;
    width: 4px;
    height: 100%;
    background: linear-gradient(180deg, transparent, #50e3c2);
    animation: animate2 3s linear infinite;
  }
  @keyframes animate2 {
    0% {
      top: -100%;
    }
    50%,
    100% {
      top: 100%;
    }
  }
  span:nth-child(3) {
    bottom: 0;
    right: 0;
    width: 100%;
    height: 4px;
    background: linear-gradient(270deg, transparent, #50e3c2);
    animation: animate3 3s linear infinite;
  }
  @keyframes animate3 {
    0% {
      right: -100%;
    }
    50%,
    100% {
      right: 100%;
    }
  }
  span:nth-child(4) {
    bottom: -100%;
    left: 0;
    width: 4px;
    height: 100%;
    background: linear-gradient(360deg, transparent, #50e3c2);
    animation: animate4 3s linear infinite;
  }
  @keyframes animate4 {
    0% {
      bottom: -100%;
    }
    50%,
    100% {
      bottom: 100%;
    }
  }
  .icon {
    animation: animate5 4s linear infinite;
  }

  @keyframes animate5 {
    0% {
      transform: rotate(360deg);
    }
    50%,
    100% {
      transform: rotate(0deg);
    }
  }
}
