.active {
  height: 48px;
  width: 94%;
  margin: 0 auto;
  padding-left: 0;
  border-radius: 8px;
  &:hover {
    background: #e5e5e5;
    p {
      color: #12d8ab;
    }
  }
  p {
    color: #626262;
    font-weight: 600;
  }
}
