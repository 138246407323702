.dashboard_box {
  .title {
    font-size: 24px;
    font-weight: 600;
    line-height: 32px;
    letter-spacing: 0em;
    text-align: center;
    color: #282938;
    margin-top: 20px;
  }
  .tow_step {
    font-size: 14px;
    font-weight: 400;
    line-height: 21px;
    letter-spacing: 0em;
    text-align: center;
    color: #a9a9af;
  }
  .monitor {
    margin-top: 30px;

    background: #fffbe7;
    border-radius: 4px;
    font-size: 14px;
    font-weight: 400;
    line-height: 21px;
    letter-spacing: 0em;
    text-align: left;
    padding: 10px;
    color: #7e7d7e;
  }
  .input_box {
    position: relative;
    img {
      position: absolute;
      top: 12px;
      left: 13px;
    }
    .input {
      width: 100%;
      height: 40px;
      background: #f4f4f4;
      border-radius: 30px;
      margin-bottom: 10px;
      padding-left: 40px;
      outline: none;
    }
  }
  .verify_box {
    display: flex;
    width: 400px;
    margin: auto;
    input {
      width: 300px;
      background: #f4f4f4;
      border-radius: 30px;
      height: 40px;
      background: #f4f4f4;
      padding-left: 12px;
      outline: none;
    }
  }
  .robot_box {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 400px;
    margin: auto;

    .img_border {
      position: relative;
      width: 300px;
      height: 300px;
      display: grid;
      // grid-template-columns: repeat(4, 1fr);
      // grid-template-rows: repeat(4, 1fr);
      img {
        position: absolute;
        z-index: -1;
      }
      .cell {
        border: 1px solid white;
        display: flex;
        align-items: center;
        justify-content: center;
      }
    }
  }

  .btn {
    margin-top: 10px;
    width: 100%;
    border-radius: 20px;
    height: 40px;
    line-height: 40px;
    background: #fcd980;
    color: #1b1c2b;
    font-weight: 500;
    text-align: center;
  }
}

@media screen and (min-width: 780px) {
  .dashboard_box {
    margin-left: 130px;
    width: calc(100% - 130px);

    .input_box {
      width: 400px;
      margin: auto;
    }
    .btn {
      display: inherit;
      line-height: 30px;
      width: 400px;
      margin: auto;
      cursor: pointer;
      margin-top: 10px;
    }
  }
}
