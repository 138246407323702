.login_box {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  // min-height: 400vh;
  height: 100%;
  width: 100%;
  background: #fff;
  backdrop-filter: blur(10px);
  z-index: 9999;
  padding: 0 20px;
  overflow-y: auto;
  padding-bottom: 30px;
  * {
    font-family: Roboto;
  }
  .close_box {
    display: flex;
    justify-content: flex-end;
    .close_icon {
      font-size: 26px;
      cursor: pointer;
      color: #cccccc;
      float: right;
      margin: 8px;
      &:hover {
        color: #1497f9;
      }
    }
  }

  .start_box {
    position: relative;
    height: calc(100vh - 58px);
    .guide {
      font-size: 18px;
      font-weight: 500;
      line-height: 27px;
      letter-spacing: 0em;
      text-align: left;
    }
    ul {
      margin-top: 24px;
      li {
        border-radius: 10pxs;
        background: #f4f6fc;
        margin-bottom: 15px;
        padding: 8px 10px;
        .title {
          font-size: 16px;
          font-weight: 500;
          line-height: 24px;
          letter-spacing: 0em;
          text-align: left;
          margin-bottom: 8px;
        }
        .subtitle {
          color: #999999;
          font-size: 16px;
          font-weight: 500;
          line-height: 24px;
          letter-spacing: 0em;
          text-align: left;
          display: flex;
          align-items: flex-start;
          margin-bottom: 2px;
          span {
            display: inline-block;
            width: 6px;
            height: 6px;
            background-color: #999999;
            margin-right: 5px;
            border-radius: 50%;
            margin-top: 9px;
          }
          div {
            width: calc(100% - 8px);
          }
        }
      }
    }

    .start_btn {
      width: 100%;
      height: 48px;
      background: #50e3c2;
      border-radius: 20px;
      text-align: center;
      line-height: 48px;
    }
  }

  .connect_box {
    .tags_box {
      width: 214px;
      height: 32px;
      background: #eeeeef;
      border-radius: 10px;
      margin: 0 auto;
      display: flex;
      padding: 2px;
      .select_li {
        background: #fff;
        color: #2405f2;
        font-weight: 600;
      }
      li {
        &:nth-child(1) {
          width: 90px;
        }
        &:nth-child(2) {
          width: 60px;
        }
        &:nth-child(3) {
          width: 60px;
        }
        border-radius: 10px;
        text-align: center;
        line-height: 28px;
        font-size: 13px;
      }
    }

    .dashboard_box {
      .title {
        font-size: 24px;
        font-weight: 600;
        line-height: 32px;
        letter-spacing: 0em;
        text-align: center;
        color: #282938;
        margin-top: 20px;
      }
      .tow_step {
        font-size: 14px;
        font-weight: 400;
        line-height: 21px;
        letter-spacing: 0em;
        text-align: center;
        color: #a9a9af;
      }
      .monitor {
        margin-top: 30px;
        background: #fffbe7;
        border-radius: 4px;
        font-size: 14px;
        font-weight: 400;
        line-height: 21px;
        letter-spacing: 0em;
        text-align: left;
        padding: 10px;
        color: #7e7d7e;
      }
      .input_box {
        position: relative;
        img {
          position: absolute;
          top: 12px;
          left: 13px;
        }
        .input {
          width: 100%;
          height: 40px;
          background: #f4f4f4;
          border-radius: 30px;
          margin-bottom: 10px;
          padding-left: 40px;
          outline: none;
        }
      }
      .robot_code {
        margin-bottom: 10px;
        width: 360px;
        height: 40px;
        display: flex;
        input {
          background: #f7f7f9;
          border-radius: 10px;
          outline: none;
          border: 0px;
          padding: 0 15px;
          font-size: 18px;
          &::placeholder {
            font-size: 16px;
            font-weight: 600px;
          }
        }
      }
      .btn {
        width: 100%;
        border-radius: 20px;
        height: 40px;
        line-height: 40px;
        background: #50e3c2;
        color: #1b1c2b;
        font-weight: 500;
        text-align: center;
      }
    }

    .verify_box {
      .title {
        font-size: 24px;
        font-weight: 600;
        line-height: 32px;
        letter-spacing: 0em;
        text-align: center;
        margin-top: 20px;
      }
      .subtitle {
        margin-top: 10px;
        font-size: 16px;
        font-weight: 400;
        line-height: 21px;
        letter-spacing: 0em;
        text-align: center;
        color: #282938;
      }
      .verify_select_box {
        margin: 0 auto;
        margin-top: 20px;
        width: 300px;
        display: flex;
        li {
          width: 100px;
          text-align: center;
        }
      }
      .tutorial {
        margin-top: 20px;
        margin-bottom: 10px;
        text-align: center;
        font-size: 18px;
        font-weight: 500;
        line-height: 27px;
        letter-spacing: 0em;
        text-align: center;
      }
      .security_box {
        padding: 16px 0;
        .input {
          width: 100%;
          height: 40px;
          background: #f4f4f4;
          border-radius: 30px;
          margin-bottom: 10px;
          padding-left: 40px;
          outline: none;
        }
        .btn {
          width: 100%;
          border-radius: 20px;
          height: 40px;
          line-height: 40px;
          background: #50e3c2;
          color: #1b1c2b;
          font-weight: 600;
          text-align: center;
        }
      }
      .sms_box {
        margin-top: 20px;
        display: flex;
        flex-wrap: wrap;
        .btn {
          width: 90px;
          font-size: 14px;
          padding: 6px 10px;
          border-radius: 20px;
          background: #50e3c2;
          height: 40px;
        }
        .input {
          width: calc(100% - 100px);
          margin-left: 10px;
          height: 40px;
          border-radius: 20px;
          background: #f4f4f4;
          outline: none;
          padding: 0 15px;
        }
        .verify {
          margin-top: 20px;
          width: 100%;
          border-radius: 20px;
          height: 40px;
          line-height: 40px;
          background: #50e3c2;
          color: #1b1c2b;
          font-weight: 600;
          text-align: center;
        }
        .tutorial {
          width: 100%;
          margin-top: 20px;
          margin-bottom: 10px;
          text-align: center;
          font-size: 18px;
          font-weight: 500;
          line-height: 27px;
          letter-spacing: 0em;
          text-align: center;
        }
        .tutorial_step {
          font-size: 14px;
          margin-bottom: 10px;
        }
      }
      .device_box {
        .btn {
          margin-top: 20px;
          width: 100%;
          border-radius: 20px;
          height: 40px;
          line-height: 40px;
          background: #50e3c2;
          color: #1b1c2b;
          font-weight: 500;
          text-align: center;
        }
      }
    }
  }

  .guide_box {
    .title {
      font-size: 16px;
      font-weight: 600;
      line-height: 32px;
      letter-spacing: 0em;
      text-align: center;
      color: #282938;
    }
    .content {
      font-size: 14px;
      font-weight: 400;
      line-height: 21px;
      letter-spacing: 0em;
      text-align: center;
      color: #282938;
    }
    .btn {
      margin-top: 14px;
      border: 1px solid #50e3c2;
      height: 48px;
      border-radius: 30px;
      text-align: center;
      font-size: 14px;
      font-weight: 600;
      letter-spacing: 0em;
      text-align: center;
      display: flex;
      justify-content: center;
      align-items: center;
      span {
        font-size: 12px;
        font-weight: 400;
        line-height: 12px;
        display: flex;
        align-items: center;
      }
    }
    .target {
      margin-top: 10px;
      font-size: 12px;
      font-weight: 400;
      line-height: 28px;
      letter-spacing: 0em;
      text-align: center;
      color: #2405f2;
    }
  }

  .app_box {
    .title {
      margin-top: 16px;
      font-size: 24px;
      font-weight: 600;
      line-height: 32px;
      letter-spacing: 0em;
      text-align: center;
    }
    .subtitle {
      margin-top: 14px;
      font-size: 14px;
      font-weight: 400;
      line-height: 21px;
      letter-spacing: 0em;
      text-align: center;
    }
    .finish {
      .input_box {
        position: relative;
        img {
          position: absolute;
          top: 12px;
          left: 13px;
        }
        .input {
          width: 100%;
          height: 40px;
          background: #f4f4f4;
          border-radius: 30px;
          margin-bottom: 10px;
          padding-left: 40px;
          outline: none;
        }
      }
      .btn {
        width: 100%;
        border-radius: 20px;
        height: 40px;
        line-height: 40px;
        background: #50e3c2;
        color: #000;
        font-weight: 500;
        text-align: center;
      }
    }
  }
  .backup_box {
    overflow-y: hidden;
    .title {
      margin-top: 10px;
      font-size: 24px;
      font-weight: 600;
      line-height: 32px;
      letter-spacing: 0em;
      text-align: center;
      color: #282938;
    }
    .subtitle {
      margin-top: 10px;
      font-size: 16px;
      font-weight: 500;
      line-height: 28px;
      letter-spacing: 0em;
      text-align: left;
    }
    .entire {
      margin-top: 10px;
      font-size: 16px;
      font-weight: 400;
      line-height: 28px;
      letter-spacing: 0em;
      text-align: left;
      color: #999999;
    }
    .ul {
      margin-top: 20px;
      background: #f4f6fc;
      padding: 10px;
      li {
        position: relative;
        padding: 4px 0;
        padding-left: 14px;
        &::before {
          content: '';
          display: block;
          width: 6px;
          height: 6px;
          background-color: #000;
          position: absolute;
          left: 0;
          top: 12px;
          border-radius: 50%;
        }
      }
    }
    .btn {
      margin-top: 20px;
      height: 48px;
      line-height: 48px;
      border-radius: 41px;
      background: #50e3c2;
      text-align: center;
    }
  }
}

@media screen and (min-width: 780px) {
  .login_box {
    border-radius: 10px;
    width: 800px;
    min-height: 800px;
    height: 800px;
    margin-top: 60px;
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    box-shadow: rgba(76, 78, 100, 0.22) 0px 10px 17px 6px;
    overflow-y: auto;
    &::-webkit-scrollbar {
      width: 4px;
    }
    &::-webkit-scrollbar-thumb {
      background-color: #d9d9d9;
    }
    .start_box {
      .start_btn {
        width: 50%;
        margin: auto;
        margin-top: 40px;
        cursor: pointer;
      }
    }
    .connect_box {
      display: flex;
      .tags_box {
        width: 140px;
        display: flex;
        flex-direction: column;
        position: absolute;
        left: 0;
        background: #f4f6fc;
        top: 0;
        height: 100%;
        padding-top: 60px;
        .select_li {
          background: #eae9fb;
          border-radius: 0;
          border-left: 3px solid #6e5bf5;
        }
        li {
          cursor: pointer;
          width: 100% !important;
          margin-bottom: 6px;
        }
      }
      .dashboard_box {
        margin-left: 130px;
        width: calc(100% - 130px);
        .input_box {
          width: 400px;
          margin: auto;
        }
        .btn {
          width: 400px;
          margin: auto;
        }
      }
      .app_box {
        margin-left: 130px;
        width: calc(100% - 130px) !important;
        .input_box {
          width: 400px;
          margin: 0px auto;
        }
        .btn {
          width: 400px;
          margin: auto;
        }
        .finish {
          min-height: auto;
        }
      }
      .verify_box {
        margin-left: 130px;
        width: calc(100% - 130px) !important;
        .btn {
          cursor: pointer;
        }
        .verify {
          cursor: pointer;
        }
      }
    }
    .backup_box {
      .btn {
        cursor: pointer;
      }
    }
  }
}
