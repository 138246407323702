@tailwind base;
@tailwind components;
@tailwind utilities;

/* @layer utilities {
  div:hover > div {
    visibility: visible;
  }
} */

:root {
  --vh: 1vh;
}

html,
body {
  min-height: 100%;
  font-family: 'Poppins' !important;
}

#__next {
  height: 100%;
}

a {
  text-decoration: none;
}

li {
  list-style: none;
}

code {
  padding: 0.1rem 0.4rem;
  font-size: 90%;
  color: #d400ff;
  border-radius: 0.1335rem;
}
code:not([class*='language-']):before,
code:not([class*='language-']):after {
  content: '`';
}
code[class*='language-'] {
  padding: 0;
}

.css-jn37e4-MuiPaper-root-MuiDrawer-paper {
  overflow-y: visible !important;
}

.my-message-custom-content {
  font-size: 24px;
  display: flex;
  align-items: center;
}

/*demo*/
.css-sdi5m0 {
  bottom: 90px !important;
  right: 2rem !important;
}

.buy-group-view-btn {
  background-color: #5a6bebec;
  border: 1px solid #e5e7eb;
  color: #fff !important;
}

.buy-group {
  width: 100% !important;
}

.buy-group-footer .buy-group-view-btn {
  margin-top: 20px;
}

/* @media (max-width: 380px) {
  .MuiToolbar-root.MuiToolbar-gutters.MuiToolbar-regular.navbar-content-container.css-1akp5d4-MuiToolbar-root {
    padding-left: 1.5rem !important;
    padding-right: 1.5rem !important;
  }
}

@media (max-width: 400px){
  .css-yhmkzy-MuiPaper-root-MuiAppBar-root{
    padding-left:0.5rem !important;
    padding-right: 1rem !important;
}
}
 */

.ping {
  position: absolute;
  width: 41%;
  height: 55%;
  border: 10px solid #fcd980;
  border-radius: 100%;
  transform: scale(0);
  animation: ping 3s cubic-bezier(0, 0, 1, 1) infinite;
}

@keyframes ping {
  0% {
    transform: scale(0);
    opacity: 0.5;
  }
  25% {
    transform: scale(1.5);
    opacity: 0.3;
  }
  50% {
    transform: scale(2);
    opacity: 0.2;
  }
  100% {
    transform: scale(3);
    opacity: 0;
  }
}

.email {
  color: blue;
}

.css-dev-only-do-not-override-1km3mtt.ant-carousel .slick-dots-bottom {
  display: none !important;
}

ul > li > button {
  display: none !important;
}

.ant-drawer-content-wrapper {
  height: 150px !important;
  overflow: hidden !important;
}

.ant-drawer-body {
  overflow: hidden !important;
}

.ant-table-footer {
}

.ant-table-title {
  background-color: #fafafa !important;
}

.vote .ant-table-tbody {
  display: none !important;
}

.ant-progress-text {
  display: none !important;
}

.ant-progress-inner {
  border-radius: 5px !important;
}

.ant-progress-bg {
  height: 50px !important;
}

.ant-progress-inner {
  width: 240px !important;
}

.ant-progress-bg {
  border-radius: 5px !important;
  background-color: #80808065 !important;
}

.ant-row {
  display: block !important;
}

.ant-col-8 {
  display: group; /* For enabling group hover */
  cursor: pointer;
  /* Background color on hover */

  /* Margin top */
  width: 100% !important;
  max-width: 100% !important;
}

.ant-anchor-link {
  /* Rounded corners on top-left and bottom-left on hover */
  /* Right border on hover */
  margin-top: 4px !important;
  padding: 5px 5px !important;
}

.ant-anchor-link:hover {
  background-color: rgba(18, 216, 171, 0.23);
  /* Rounded corners on top-left and bottom-left on hover */
  border-radius: 0.25rem 0 0 0.25rem;
  /* Right border on hover */
  margin-top: 4px !important;
}

.ant-anchor-link-active {
  background-color: rgba(18, 216, 171, 0.23);
  /* Rounded corners on top-left and bottom-left on hover */
  border-radius: 0.25rem 0 0 0.25rem;
  /* Right border on hover */
  border-right-width: 2px;
  border-color: #12d8ab;
  margin-top: 4px !important;
  padding-left: 10px !important;
}

.ant-anchor-ink {
  display: none !important;
  padding-inline: 0 !important;
}

.ant-anchor-link {
  padding-inline: 0 !important;
}

.ant-anchor::before {
  display: none !important;
}

.ant-anchor-link-title {
  overflow: auto !important;
  display: flex !important;
  white-space: normal !important;
  padding-left: 10px !important;
  align-items: center;
}

.ant-anchor-link-title::before {
  content: '·'; /* The content you want to insert before the link text */
  margin-right: 10px; /* Adjust the spacing between the dot and the link text as needed */
  font-size: 28px !important;
}

.ant-drawer-content-wrapper {
  height: auto !important;
}

.Slug .ant-anchor-link-title {
  /* Rounded corners on top-left and bottom-left on hover */
  /* Right border on hover */
  color: #fff !important;
  padding-left: 0 !important;
}

.Slug .ant-anchor-link {
  padding: 0 !important;
  color: rgba(255, 255, 255, 0.274) !important;
}

.Slug .ant-anchor-link a {
  padding: 0 !important;
  color: rgba(255, 255, 255, 0.678) !important;
}

.Slug .ant-drawer-body {
  padding: 0 !important;
}

.Slug .ant-row {
  padding: 0 !important;
  padding-right: 10px;
}

.Slug .ant-anchor-link-active a {
  color: white !important;
}

/* .css-1akp5d4-MuiToolbar-root{
  padding: 0 !important;
} */

/* demo顶部移动端效果 */
@media (max-width: 700px) {
  .css-yhmkzy-MuiPaper-root-MuiAppBar-root {
    padding-left: 0 !important;
    padding-right: 0 !important;
  }

  .css-kchx4v-MuiToolbar-root {
    border: none !important;
    border-bottom-left-radius: 0px !important;
    border-bottom-right-radius: 0px !important;
  }

  .css-1akp5d4-MuiToolbar-root {
    padding-left: 0 !important;
    padding-right: 0 !important;
    border-bottom-left-radius: 0px !important;
    border-bottom-right-radius: 0px !important;
  }

  .MuiBox-root .css-184klcz {
    width: 96% !important;
    margin-left: auto;
    margin-right: auto;
  }

  .MuiToolbar-root.css-1akp5d4-MuiToolbar-root {
    padding-left: 0 !important;
    padding-right: 0 !important;
  }
}

.DemoSnackbar .MuiPaper-root {
  background-color: #fff !important;
}

.bgImg {
  background-image: url('/image/Pricing/bg.svg');
  background-position: bottom;
}

.gradient-text {
  background-image: linear-gradient(90deg, #724fff 0, #00c2ff 100%);
  -webkit-background-clip: text;
  background-clip: text;
  -webkit-text-fill-color: transparent;
  -webkit-box-decoration-break: clone;
  box-decoration-break: clone;
}

.Pfeatures .ant-collapse {
  border: none !important;
  border-radius: 0 !important;
  background-color: #f4f6fc !important;
  border-top: 1px solid #e5e7eb !important;
  border-radius: 0 0 10px 10px;
  border-bottom-right-radius: 0.75rem !important;
  border-bottom-left-radius: 0.75rem !important;
  --tw-shadow: 0 10px 10px -5px rgba(0, 0, 0, 0.2) !important;
  --tw-shadow-colored: 0 0px 30px -5px var(--tw-shadow-color) !important;
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow) !important;
}

.Pfeatures .ant-collapse-header {
  padding: 0 !important;
}

.Pfeatures .ant-collapse .ant-collapse-item {
  border-radius: 0 0 10px 10px;
  border-bottom: 0 !important;
}

.Pfeatures .ant-collapse-expand-icon {
  display: none !important;
}

.Pfeatures .ant-collapse-content-box {
  padding: 0 !important;
}

.Pfeatures2 .ant-collapse {
  border: none !important;
  border-radius: 0 !important;
  background-color: #12d8ab !important;
  border-top: 1px solid #e5e7eb57 !important;
  border-radius: 0 0 10px 10px;
  border-bottom-right-radius: 0.75rem !important;
  border-bottom-left-radius: 0.75rem !important;
  --tw-shadow: 0 10px 10px -5px rgba(0, 0, 0, 0.2) !important;
  --tw-shadow-colored: 0 0px 30px -5px var(--tw-shadow-color) !important;
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow) !important;
}

.Pfeatures2 .ant-collapse-header {
  padding: 0 !important;
}

.Pfeatures2 .ant-collapse .ant-collapse-item {
  border-radius: 0 0 10px 10px;
  border-bottom: 0 !important;
}

.Pfeatures2 .ant-collapse-expand-icon {
  display: none !important;
}

.Pfeatures2 .ant-collapse-content-box {
  padding: 0 !important;
}

.Feature .MuiAccordionDetails-root {
  margin-bottom: -20px;
}

/* 地图样式 */
.GPS .leaflet-container {
  z-index: 0;
}

.GPS .leaflet-popup {
  bottom: 28px !important;
}

.GPS .leaflet-marker-icon {
  margin-left: -25px !important;
  margin-top: -43px !important;
}

.GPS .custom-cluster-icon {
  position: relative;
}

.GPS .custom-cluster-icon {
  position: absolute;
}

.GPS .cluster-count {
  width: 56px;
  height: 55px;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 20;
  position: relative;
}

.GPS .leaflet-container a.leaflet-popup-close-button {
  padding: 0 !important;
}

.GPS #LeafletMap {
  width: 100%;
  height: 100%;
}

.GEO .leaflet-popup-content {
  width: 330px !important;
}

.Guide .css-1ryrfmt-MuiAccordionDetails-root {
  padding: 0 !important;
}

@media (max-width: 1200px) {
  .Guide .Father {
    justify-content: flex-start !important;
  }
}

.Guide2 .ant-anchor-link-title::before {
  display: none !important;
}

.Guide2 .ant-anchor-link-title {
  margin-left: 15px;
  color: black !important;
}

.Guide2 .ant-anchor-link:hover {
  background-color: #fff !important;
  /* Rounded corners on top-left and bottom-left on hover */
  border-radius: 0.25rem 0 0 0.25rem;
  /* Right border on hover */
}

.Guide2 .ant-anchor-link-active {
  background-color: white !important;
  border-radius: 0 !important;
  border-right-width: 0 !important;
  border-color: none !important;
  color: blue !important;
}

.Guide2 .ant-anchor-link-active a {
  color: blue !important;
}

.Guide2 .Title a {
  font-size: 18px !important;
  margin-left: 5px;
}

.track-iphone-title a {
  font-size: 14px !important;
}

.track-iphone-title2 a {
  margin-left: 20px !important;
}

@media (max-width: 800px) {
  .choose-device-button {
    width: calc(100vw - 20vw) !important;
  }
}

/* 固定demo头部 */
.css-ydyk5j {
  width: 105vw !important;
  transition: none !important;
}

.css-zm970 {
  transition: none !important;
}

/* 博客左侧锚点折叠样式 */
.Blog .MuiAccordionSummary-content {
  width: 100%;
}

.Blog .MuiAccordionSummary-expandIconWrapper {
  position: absolute;
  right: 0;
}

.Blog .ant-anchor-link-title {
  width: 90%;
}

.Blog .css-6grp1-MuiAccordionSummary-content {
  margin: 0;
}

.Blog .css-6grp1-MuiAccordionSummary-content.Mui-expanded {
  margin: 0;
}

.Blog .css-h126fq-MuiPaper-root-MuiAccordion-root {
  box-shadow: none;
}

.Blog .css-1hpvzlq-MuiButtonBase-root-MuiAccordionSummary-root {
  padding: 0;
}

.Blog .css-h126fq-MuiPaper-root-MuiAccordion-root.Mui-expanded {
  box-shadow: none;
}

.Blog .css-h126fq-MuiPaper-root-MuiAccordion-root.Mui-expanded {
  margin: 0;
}

.gps-time-line .mt-2 .ant-timeline .ant-timeline-item .ant-timeline-item-label {
  width: 70px;
}
.gps-time-line .mt-2 .ant-timeline .ant-timeline-item .ant-timeline-item-head {
  inset-inline-start: 80px;
}
.gps-time-line .mt-2 .ant-timeline .ant-timeline-item .ant-timeline-item-tail {
  inset-inline-start: 80px;
}
.gps-time-line .mt-2 .ant-timeline .ant-timeline-item .ant-timeline-item-content {
  inset-inline-start: 80px;
  width: calc(100% - 110px);
}

.equipment .ant-modal-content {
  padding: 0px 0px;
}
.equipment .ant-modal-footer {
  padding: 10px;
  border-top: 1px solid #e3e3e8;
}

.tips .ant-modal-footer {
  padding: 0px;
  border-top: none;
}

.ant-select-dropdown {
  min-width: 200px !important;
  z-index: 9999;
}
.auto-renewal .ant-switch {
  background: #ccc;
}
.auto-renewal .ant-switch-checked {
  background: #1677ff;
}
.ant-progress-text {
  color: #fff !important;
}
.ant-message {
  z-index: 9999;
}
.rate .ant-progress-text {
  color: #000 !important;
}
.ant-progress-line {
  margin-bottom: 0px !important;
}

.chat_box {
  word-break: break-all;
}
.layout-header {
}
.bread-crumbs {
  font-size: 20px !important;
}
.current-language {
  font-size: 18px !important;
}
.digit_key::placeholder {
  font-size: 17px;
  font-weight: 600px;
  color: #a1a7b3;
}
.ant-tabs-dropdown {
  z-index: 9999;
}
.android_login_tas .ant-tabs-content {
  height: 73vh;
  overflow: auto;
}
.android_login_tas .ant-tabs-tab-btn {
  font-weight: 600;
  font-size: 17px;
  color: #383838;
}
.android_login_tas .ant-tabs-tab-active .ant-tabs-tab-btn {
  color: #4930ee !important;
}
.android_login_tas .ant-tabs-nav .ant-tabs-ink-bar {
  background: #4930ee;
}
@media (max-width: 780px) {
  .current-language {
    font-size: 14px !important;
  }
  .bread-crumbs {
    font-size: 14px !important;
  }
  .layout-header {
    padding-left: 0.5rem !important;
    padding-right: 0.5rem !important;
  }
  .codebox-container .codebox-field-wrap {
    width: 2.2125rem !important;
    height: 2.2125rem !important;
  }

  .ant-picker-dropdown {
    left: 30px !important;
  }
  .gps-tabs .ant-tabs-nav .ant-tabs-nav-wrap {
    display: flex;
    justify-content: flex-end;
  }
  .gps-map {
    height: calc(100vh - 250px) !important;
  }
  .chat_box {
    width: 100% !important;
    word-break: break-all;
  }
  .email_html * {
    max-width: calc(100% - 20px) !important;
    margin: 0 auto;
    overflow: scroll;
  }
}

.pc {
  display: block;
}
.move {
  display: none;
}
@media (max-width: 420px) {
  .pc {
    display: none;
  }
  .move {
    display: block;
  }
}

.Blog .css-e74szk {
  padding: 0 !important;
}

.Blog .css-nmofrl {
  padding: 0 !important;
}

.Blog .css-d8mg4y.Mui-expanded {
  margin: 0 !important;
}

.Blog .css-1ryrfmt-MuiAccordionDetails-root {
  padding: 0 !important;
}

@media screen and (min-width: 780px) {
  .android_login_tas .ant-tabs-nav {
    width: 140px;
    position: absolute;
    left: 0;
    top: 0;
    height: 100%;
    background: #f4f6fc;
    padding-top: 50px;
  }
  .android_login_tas .ant-tabs-nav .ant-tabs-tab-active {
    background: #eae9fb;
  }
  .android_login_tas .ant-tabs-nav .ant-tabs-tab-active .ant-tabs-tab-btn {
    color: #543df4;
  }
  .android_login_tas .ant-tabs-nav .ant-tabs-ink-bar {
    left: 0;
    background: #2f13e9;
  }

  .android_login_tas .ant-tabs-content-holder {
    margin-left: 130px;
    width: calc(100% - 130px);
    border-left: 0;
  }
  .android_login_tas .ant-tabs-content {
    height: 670px;
    overflow-y: auto;
  }
  .android_login_tas .ant-tabs-content::-webkit-scrollbar {
    width: 4px;
  }
  .android_login_tas .ant-tabs-content::-webkit-scrollbar-thumb {
    background-color: #d9d9d9;
  }

  .android_login_tas .ant-tabs-tab-btn {
    font-weight: 600;
    font-size: 15px;
    color: #383838;
  }
}

.setup .slick-dots {
  bottom: -30px !important;
}

.setup .slick-dots {
  display: none !important;
}

.setup .slick-dots li {
  display: none !important;
}

/* demo顶部移动端效果 */
@media (max-width: 700px) {
  .css-1rf9f20-MuiToolbar-root {
    padding-left: 0 !important;
    padding-right: 0 !important;
    backdrop-filter: none !important;
    background: white !important;
    padding-left: 0 !important;
    padding-right: 0 !important;
  }

  .css-1rf9f20-MuiToolbar-root {
    box-shadow: none !important;
  }

  .css-yhmkzy-MuiPaper-root-MuiAppBar-root {
    padding-left: 0 !important;
    padding-right: 0 !important;
  }

  .css-kchx4v-MuiToolbar-root {
    border: none !important;
    border-bottom-left-radius: 0px !important;
    border-bottom-right-radius: 0px !important;
  }

  .css-1akp5d4-MuiToolbar-root {
    padding-left: 0 !important;
    padding-right: 0 !important;
    border-bottom-left-radius: 0px !important;
    border-bottom-right-radius: 0px !important;
  }

  .MuiBox-root .css-184klcz {
    width: 96% !important;
    margin-left: auto;
    margin-right: auto;
  }

  .MuiToolbar-root.css-1akp5d4-MuiToolbar-root {
    padding-left: 0 !important;
    padding-right: 0 !important;
  }
}

/*付费卡号*/

#ab-card-element {
  height: 100px;
}
#card-errors {
  height: 30px;
  display: block !important;
}

.CheckoutModal .ant-btn {
  background-color: #eee;
  display: none;
}

.Local-step {
  height: calc(100vh - 115px);
}

@media (min-height: 770px) {
  .LinkDevice {
    margin-top: calc(100vh - 845px) !important;
  }
}

@media (max-height: 770px) {
  .setupHeight {
    padding-bottom: 0 !important;
  }
  .setupDiv {
    padding-bottom: 50px !important;
    overflow: auto;
    height: 100%;
  }
}

@media (max-width: 770px) {
  .setupMobile {
    height: calc(100vh - 140px) !important;
  }
}

/* setup apk url复制弹窗 */
.ant-message {
  z-index: 999999999 !important;
}

.local-switch .ant-switch .ant-switch-handle::before {
  width: 21px;
  height: 21px;
  top: -3.6px;
  bottom: 4px;
  border-radius: 50%;
  box-shadow: 1px 2px 4px 2px rgba(0, 35, 11, 0.2);
  left: -5px;
}

.local-switch .ant-switch {
  width: 35px;
  height: 18px;
}

/* set-geofence */
.ant-picker-dropdown .ant-picker-footer ul li .ant-btn {
  display: block !important;
  background: blue;
  margin-top: 6px;
  color: #fff;
}


@media (max-width: 768px) {
  .ant-picker-panel:nth-child(2) {
    display: none;
  }

  /* 新ui的浮窗样式 */
  #customRangePicker .ant-picker-dropdown {
    width: 100% !important;
  }
  #customRangePicker .ant-picker-panel-container {
    margin: 0px !important;
  }
  #customRangePicker .ant-picker-panel-layout {
    width: 288px;
    overflow-x: unset !important;
    overflow-y: unset !important;
  }
  #customRangePicker .ant-picker-panels {
    display: initial !important;
  }
  #customRangePicker .ant-picker-panel:first-child .ant-picker-header .ant-picker-header-next-btn,
  #customRangePicker .ant-picker-panel:first-child .ant-picker-header .ant-picker-header-super-next-btn {
    visibility: visible !important;
  }

  #customRangePicker .ant-picker-dropdown {
    left: 15px !important;
  }
}